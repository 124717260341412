<template>
  <div class="main-wrapper">
    <div class="quick-count-wrapper">
      <div class="container">
        <div class="ads-main">
          <Billboard />
        </div>
        <QuickCount
          :linkSelengkapnya="null"
          :showKpu="true"
          :showTable="true"
          :showProvider="true"
          :showParpol="true"
        />
        <div class="share-container">
          <div class="share-wrapper">
            <a
              :href="setLink('whatsapp')"
              target="_blank"
              rel="noopener"
              class="sosmed-wrapper"
            >
              <Icons color="#57C038" name="logo-whatsapp" />
            </a>
            <a
              :href="setLink('twitter')"
              target="_blank"
              rel="noopener"
              class="sosmed-wrapper"
            >
              <Icons color="#000000" name="logo-x" />
            </a>
            <a
              :href="setLink('facebook')"
              target="_blank"
              rel="noopener"
              class="sosmed-wrapper"
            >
              <Icons color="#415893" name="logo-facebook" />
            </a>
            <div class="sosmed-wrapper" @click="copyLink()">
              <Icons color="#D0D0D0" name="logo-copy-link" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="terkini-sidebar">
      <div class="container">
        <div class="wrapper">
          <div class="primary">
            <TitleSection title="ARTIKEL TERKAIT" borderColor="#FF4800" />
            <div class="list-article-terkini">
              <template v-if="$store.state.suaraPenentu.terkini.loading">
                <ShimmerCardHomeLandscape
                  v-for="(item, index) in 5"
                  :key="index"
                />
              </template>
              <div
                v-show="!$store.state.suaraPenentu.terkini.loading"
                class="article-terkini"
                v-for="(article, index) in screen < 1000
                  ? $store.state.suaraPenentu.terkini.items.slice(0, 5)
                  : $store.state.suaraPenentu.terkini.items"
                :key="`list-article-terkini-${index}`"
              >
                <Link :to="article.slug">
                  <div class="position-relative">
                    <VLazyImage
                      :src="`https://images.narasi.tv/preset:sharp/resize:fill:312:174:1/gravity:ce/plain/${article.thumbnail}@webp`"
                      alt=""
                    />
                    <span class="time-video">{{
                      article.type === "read" ? "" : article.timeVideo
                    }}</span>
                  </div>
                  <h3>{{ article.title }}</h3>
                </Link>
              </div>

              <div
                class="button-show-more"
                :class="{
                  'mt-5': $store.state.suaraPenentu.terkini.loadingShowMore,
                }"
              >
                <span
                  v-if="
                    pageArticles < $store.state.suaraPenentu.terkini.pages &&
                    !$store.state.suaraPenentu.terkini.loadingShowMore
                  "
                  @click="loadMoreTerkini"
                  >LIHAT LEBIH BANYAK</span
                >
                <div
                  v-if="$store.state.suaraPenentu.terkini.loadingShowMore"
                  class="dot-spin m-auto"
                ></div>
              </div>
            </div>
          </div>
          <div class="secondary">
            <MiddleSquareAds />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import QuickCount from "../../components/QuickCount.vue";
import TitleSection from "../../components/TitleSection.vue";
import ShimmerCardHomeLandscape from "@/components/shimmer/CardHomeLandscape";
import VLazyImage from "v-lazy-image/v2";
import MiddleCenterAds from "../../components/ads/suaraPenentu/MiddleCenterAds.vue";
import Link from "@/components/Link";
import MiddleSquareAds from "../../components/ads/suaraPenentu/MiddleSquareAds.vue";
import config from "../../config";
import { mapGetters } from "vuex";
import Icons from "../../components/Icons.vue";
import Billboard from "../../components/ads/homepage/Billboard.vue";

export default {
  components: {
    Billboard,
    Icons,
    QuickCount,
    TitleSection,
    ShimmerCardHomeLandscape,
    VLazyImage,
    Link,
    MiddleCenterAds,
    MiddleSquareAds,
  },
  asyncData({ store, route, redirect }) {
    redirect(301, "/");
    store.commit("seo/SET_SEO", {
      title: "Pemilu 2024: Quick Count dan Hasil Perhitungan Cepat",
      desc: "Pantau perkembangan Pemilu Presiden 2024 dengan quick count terkini dari KPU dan Lembaga Survei. Dapatkan hasil perhitungan cepat dan update real-time untuk mengetahui perkembangan suara calon presiden secara akurat dan langsung di halaman ini.",
      image: "https://narasi.tv/icon.png",
      url: `${config.BASE_URL}${route.fullPath}`,
      path: `${config.BASE_URL}${route.path}`,
    });
  },
  head() {
    return {
      ...this.headReturn,
    };
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
  },
  data() {
    return {
      pageArticles: 1,
      url: "",
    };
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(
        this.url.includes("uid")
          ? this.url + "&utm_source=copy_link&utm_medium=share"
          : this.url + "?utm_source=copy_link&utm_medium=share"
      );
      this.$toast.success("Copy Link Success");
    },
    setLink(param) {
      if (this.url.includes("uid")) {
        switch (param) {
          case "whatsapp":
            return (
              "https://wa.me/?text=" +
              this.url +
              "%26utm_source=whatsapp%26utm_medium=share"
            );
          case "facebook":
            return (
              "https://www.facebook.com/sharer.php?u=" +
              this.url +
              "&utm_source=facebook&utm_medium=share"
            );
          case "twitter":
            return (
              "https://twitter.com/intent/tweet?url=" +
              this.url +
              "&utm_source=twitter&utm_medium=share"
            );
        }
      } else {
        switch (param) {
          case "whatsapp":
            return (
              "https://wa.me/?text=" +
              this.url +
              "%3Futm_source=whatsapp%26utm_medium=share"
            );
          case "facebook":
            return (
              "https://www.facebook.com/sharer.php?u=" +
              this.url +
              "?utm_source=facebook&utm_medium=share"
            );
          case "twitter":
            return (
              "https://twitter.com/intent/tweet?url=" +
              this.url +
              "?utm_source=twitter&utm_medium=share"
            );
        }
      }
    },
    loadMoreTerkini() {
      this.pageArticles = this.pageArticles + 1;
      this.$store.dispatch("suaraPenentu/getArticlesByTerkini", {
        tagsSlug: "suara-penentu",
        page: this.pageArticles,
        limit: 10,
      });
    },
  },
  mounted() {
    this.$store.dispatch("suaraPenentu/getArticlesByTerkini", {
      tagsSlug: "suara-penentu,suara-penentu-populer",
      page: 1,
      limit: 10,
      firstLoad: true,
    });
    this.url = window.location.href;
  },
};
</script>

<style lang="scss" scoped>
.quick-count-wrapper {
  padding: 16px 0;
  .ads-main {
    padding: 16px 0;
  }
}
.share-container {
  position: relative;
  .share-wrapper {
    cursor: pointer;
    position: fixed;
    right: calc((100vw - 82%) / 2);
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 2px 6px 0px #00000040;
    background: #ffffff;
    border-radius: 32px;
    padding: 8px;
    gap: 8px;
    @media only screen and (max-width: 1024px) {
      right: calc((100vw - 91%) / 2);
      top: 83%;
    }
    .icon-wrapper {
      background: #4a25aa;
      height: 32px;
      width: 32px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      font-size: 14px;
      color: #0c0b0d;
    }
  }
}
.terkini-sidebar {
  background-color: #fff;
  padding: 32px 0;
  .container {
    @media only screen and (max-width: 1024px) {
      padding: 0;
    }
    .wrapper {
      display: flex;
      gap: 32px;
      @media only screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        .header-mobile {
          margin-top: 40px;
        }
      }
      .primary {
        width: 70%;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          padding: 0 0.75rem;
        }
        .title {
          margin-bottom: 24px;
        }
        .list-article-terkini {
          .article-terkini {
            border-bottom: 1px solid #d9d9d9;
            padding: 24px 0;
            @media only screen and (max-width: 1024px) {
              padding: 16px 0;
            }
            &:first-child {
              padding-top: 0;
            }
            &:nth-last-child(2) {
              border-bottom: none !important;
            }

            a {
              display: flex;
              gap: 16px;
              align-items: center;

              img {
                width: 220px;
                min-width: 220px;
                height: 124.103px;
                min-height: 124.103px;
                object-fit: cover;
                @media only screen and (max-width: 1024px) {
                  min-width: 100px;
                  min-height: 100px;
                  width: 100px;
                  height: 100px;
                }
              }

              .time-video {
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.7);
                padding: 6px 8px;
                color: #fff;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                font-weight: 500;
                border-top-left-radius: 8px;
              }

              h3 {
                color: #0c0b0d;
                font-size: 16px;
                @media only screen and (max-width: 1024px) {
                  font-size: 14px;
                }
              }
            }
          }
          .button-show-more {
            text-align: center;
            margin: 16px 0;
            span {
              cursor: pointer;
              height: 50px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #4a25aa;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 700;
              color: #4a25aa;
              padding: 0 20px;
              @media only screen and (max-width: 1024px) {
                height: 40px;
                &:hover {
                  color: #4a25aa !important;
                }
              }
              @media only screen and (min-width: 1024px) {
                &:hover {
                  color: #8166c8 !important;
                  border-color: #8166c8 !important;
                }
              }
            }
          }
        }
      }
      .secondary {
        width: 30%;
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
        .sidebar {
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
          .title {
            @media only screen and (max-width: 1024px) {
              margin: 0 0.75rem;
            }
          }
          .description {
            margin-bottom: 0;
            font-size: 14px;
            color: #7d7d7d;
            line-height: 130%;
            @media only screen and (max-width: 1024px) {
              padding: 0 0.75rem;
              font-size: 13px;
            }
          }

          iframe {
            position: relative;
            z-index: 998;
            width: 100%;
            height: 500px;
          }
          .tagcloud {
            width: 100%;
            height: 318px;
            background-color: #564480;
            border-radius: 12px;
            padding: 13px;
            @media only screen and (max-width: 1024px) {
              margin: 0 0.75rem;
            }
          }
          .suara-rakyat {
            width: 100%;
            background-color: #ffb400;
            border-radius: 12px;
            padding: 32px;
            @media only screen and (max-width: 1024px) {
              padding: 16px;
              margin: 0 0.75rem;
            }

            p {
              font-size: 24px;
              color: #564480;
            }

            img {
              margin: 0 auto;
            }

            .btn-explore-profile {
              padding: 12px 24px;
              width: 100%;
              border-radius: 50px;
              background: #564480;
              color: #fff;
              border: none;

              &:hover {
                background: hwb(258 18% 49%);
                color: #fff !important;
              }
            }
          }
          .terpopuler {
            width: 100%;
            background-color: rgba(221, 221, 221, 0.87);
            border-radius: 12px;
            padding: 32px;
            @media only screen and (max-width: 1024px) {
              padding: 16px;
              border-radius: 0;
            }
            .header {
              display: flex;
              justify-content: flex-start;
              .title {
                display: flex;
                gap: 16px;
              }
            }
            .list-artikel-terpopuler {
              display: flex;
              flex-direction: column;
              .article {
                border-bottom: 1px solid #b3afaf;
                padding: 16px 0;

                &:last-child {
                  border-bottom: unset;
                }

                a {
                  color: #0c0b0d;
                  font-size: 14px;

                  .material-icons {
                    font-size: 14px;
                    color: #564480;
                  }

                  &:hover {
                    color: #564480 !important;
                  }
                }
              }
            }
            .button-show-more {
              text-align: center;
              margin-top: 24px;
              span {
                cursor: pointer;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #4a25aa;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 700;
                color: #4a25aa;
                padding: 0 20px;
                @media only screen and (max-width: 1024px) {
                  height: 40px;
                  &:hover {
                    color: #4a25aa !important;
                  }
                }
                @media only screen and (min-width: 1024px) {
                  &:hover {
                    color: #8166c8 !important;
                    border-color: #8166c8 !important;
                  }
                }
              }
            }
          }
        }
      }
      .tertiary {
        @media only screen and (max-width: 1024px) {
          padding: 0 0.75rem;
        }
        .list-article-terkini-mobile-bottom {
          .article-terkini {
            border-bottom: 1px solid #d9d9d9;
            padding: 24px 0;
            @media only screen and (max-width: 1024px) {
              padding: 16px 0;
            }
            &:nth-child(1) {
              padding-top: 16px;
            }

            &:last-child {
              border-bottom: unset !important;
            }

            a {
              display: flex;
              gap: 16px;
              align-items: center;

              img {
                width: 220px;
                min-width: 220px;
                height: 124.103px;
                min-height: 124.103px;
                object-fit: cover;
                @media only screen and (max-width: 1024px) {
                  min-width: 100px;
                  min-height: 100px;
                  width: 100px;
                  height: 100px;
                }
              }

              .time-video {
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.7);
                padding: 6px 8px;
                color: #fff;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                font-weight: 500;
                border-top-left-radius: 8px;
              }

              h3 {
                color: #0c0b0d;
                font-size: 16px;
                @media only screen and (max-width: 1024px) {
                  font-size: 14px;
                }
              }
            }
          }
          .button-show-more {
            text-align: center;
            margin-bottom: 32px;
            margin-top: 32px;
            span {
              cursor: pointer;
              height: 50px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #4a25aa;
              border-radius: 4px;
              font-size: 14px;
              font-weight: 700;
              color: #4a25aa;
              padding: 0 20px;
              @media only screen and (max-width: 1024px) {
                height: 40px;
                &:hover {
                  color: #4a25aa !important;
                }
              }
              @media only screen and (min-width: 1024px) {
                &:hover {
                  color: #8166c8 !important;
                  border-color: #8166c8 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
