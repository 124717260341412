<template>
  <div class="rekam-jejak">
    <div class="container">
      <div class="main-rekam-jejak">
        <div class="main">
          <section id="personal-info">
            <div class="header">
              <span>Powered by</span>
              <ImageResponsive
                imageUrl="suara-penentu/logo-rekam-jejak.png"
                class="logo-rekam-jejak"
              />
            </div>
            <div class="profile">
              <ImageResponsive
                :imageUrl="response.data.gambar"
                :fromUrl="true"
                :fromApi="false"
                :width="'150'"
                :height="'150'"
                widthMobile="90"
                heightMobile="90"
                class="profile-img"
              />
              <div class="info">
                <h1>{{ response.data.nama }}</h1>
                <div class="parpol">
                  <div class="partai">
                    <span>Partai Politik:</span>
                    <div class="pengusung">
                      <ImageResponsive
                        :imageUrl="
                          response.data.karir_parpol.length > 0
                            ? response.data.karir_parpol[0].parpol.gambar
                            : null
                        "
                        :fromUrl="true"
                        :fromApi="false"
                        widthMobile="32"
                        heightMobile="32"
                        :width="'32'"
                        :height="'32'"
                        class="parpol-img"
                      />
                      <span>{{
                        response.data.karir_parpol.length > 0
                          ? response.data.karir_parpol[0].parpol.nama
                          : "Tidak ada data."
                      }}</span>
                    </div>
                  </div>
                  <div class="daerah">
                    <span
                      >Daerah Pemilihan:
                      {{
                        response.data.ikut_pemilu.length > 0
                          ? response.data.ikut_pemilu[0].dapil.nama
                          : "Tidak ada data."
                      }}</span
                    >
                    <span
                      >No. Urut:
                      {{
                        response.data.ikut_pemilu.length > 0
                          ? response.data.ikut_pemilu[0].nomor_urut
                          : "Tidak ada data."
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="identitas">
              <div class="basic">
                <div class="info">
                  <div class="column">
                    <label>Lahir</label>
                    <span>{{ response.data.tgllahir }}</span>
                    <span>(umur {{ response.data.umur }} tahun)</span>
                    <span>{{ response.data.tempatlahir }}</span>
                  </div>
                  <div class="column">
                    <label>Alamat</label>
                    <span>{{ response.data.alamat }}</span>
                  </div>
                  <div
                    class="column"
                    v-if="
                      response.data.facebook ||
                      response.data.instagram ||
                      response.data.twitter
                    "
                  >
                    <label>Media Sosial</label>
                    <div class="social-media">
                      <a
                        v-if="response.data.facebook"
                        target="_blank"
                        :href="response.data.facebook"
                        ><img
                          alt="Facebook"
                          src="/storage/images/suara-penentu/social-media/facebook.svg"
                      /></a>
                      <a
                        v-if="response.data.instagram"
                        target="_blank"
                        :href="response.data.instagram"
                        ><img
                          alt="Instagram"
                          src="/storage/images/suara-penentu/social-media/instagram.svg"
                      /></a>
                      <a
                        v-if="response.data.twitter"
                        target="_blank"
                        :href="response.data.twitter"
                        ><img
                          alt="Twitter"
                          src="/storage/images/suara-penentu/social-media/twitter.svg"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="summary">
                <h2>Profil</h2>
                <div class="content" v-html="response.data.highlight"></div>
              </div>
            </div>
          </section>
          <section id="history">
            <h2 class="title-column">Riwayat</h2>
            <div class="position-relative">
              <div
                class="swiper-card"
                v-swiper:swiperHistory="swiperOptions.history"
              >
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide history"
                    v-for="(history, index) in riwayat"
                    :key="`history-${index}`"
                  >
                    <div class="title-swiper">
                      <img
                        :src="`/storage/images/suara-penentu/history/${history.logo}`"
                        alt="Pekerjaan"
                      />
                      <span>{{ index }}</span>
                    </div>
                    <div class="content-swiper">
                      <ul>
                        <li
                          v-for="(item, idx) in history.items"
                          :key="`task-${index}-${idx}`"
                        >
                          {{ item.value }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="shadow-arrow shadow-left prev-history">
                <div class="icon-wrapper">
                  <Icons name="arrow-left" color="#fff" class="arrow prev" />
                </div>
              </div>
              <div class="shadow-arrow shadow-right next-history">
                <div class="icon-wrapper">
                  <Icons name="arrow-right" color="#fff" class="arrow next" />
                </div>
              </div>
            </div>
          </section>
          <section id="family">
            <h2 class="title-column">Kerabat</h2>
            <div class="list-family">
              <div
                class="family-item"
                v-for="(family, index) in response.data.kekerabatan"
                :key="`family-${index}`"
              >
                <ImageResponsive
                  :imageUrl="family.kepada_individu.gambar"
                  :fromUrl="true"
                  :fromApi="false"
                  :width="'30'"
                  widthMobile="30"
                  heightMobile="30"
                  :height="'30'"
                  class="profile-img"
                />
                <div class="figure">
                  <div class="name">{{ family.kepada_individu.nama }}</div>
                  <div class="role">{{ mappingSaurada[family.relasi] }}</div>
                </div>
              </div>
            </div>
          </section>
          <section id="report">
            <h2 class="title-column">
              Laporan Harta Kekayaan Penyelenggara Negara (LHKPN)
            </h2>
            <span v-if="response.data.data_lhkpn.length === 0"
              >Tidak ada data.</span
            >
            <div v-else class="list-reports">
              <div
                class="report-item"
                v-for="(report, index) in response.data.data_lhkpn"
                :key="`report-${index}`"
              >
                <div class="year-patuh">
                  <span>{{ report.tahun_pelaporan }}</span>
                  <ImageResponsive
                    :imageUrl="`suara-penentu/${report.status_lhkpn}.png`"
                    :fromUrl="false"
                    :fromApi="false"
                    :height="'24'"
                    width="24"
                    class="status-img"
                  />
                </div>
                <ul class="list-report-item">
                  <li>Tanggal dilaporkan: {{ report.tanggal_dilaporkan }}</li>
                  <li>Jenis laporan: {{ report.jenis_laporan }}</li>
                  <li>Kepatuhan deskripsi: {{ report.kepatuhan_deskripsi }}</li>
                </ul>
              </div>
            </div>
          </section>
          <section id="issue">
            <h2 class="title-column">Berita dan Isu</h2>
            <span v-if="response.data.keterlibatan_korupsi.length === 0"
              >Tidak ada data.</span
            >
            <div v-else class="list-issue">
              <div
                class="issue-item"
                v-for="(item, index) in response.data.keterlibatan_korupsi"
                :key="`issue-${index}`"
              >
                <h3>{{ item.judulisu }}</h3>
                <p v-html="item.lengkap"></p>
                <a target="_blank" :href="item.sumber_informasi">{{
                  item.sumber_informasi
                }}</a>
              </div>
            </div>
          </section>
          <section id="document">
            <h2 class="title-column">Dokumen</h2>
            <div class="list-document">
              <h3>Daftar LHKPN</h3>
              <p v-if="response.data.info_anggota.length === 0">
                Tidak ada data.
              </p>
              <div v-else class="position-relative">
                <div
                  class="swiper-card"
                  v-swiper:swiperFile="swiperOptions.lhkpn"
                >
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide files"
                      v-for="(file, index) in response.data.info_anggota"
                      :key="`history-${index}`"
                    >
                      <div class="file-name">
                        <ImageResponsive
                          imageUrl="suara-penentu/pdf.png"
                          :fromUrl="false"
                          :fromApi="false"
                          :height="'40'"
                          width="40"
                          class="pdf-img"
                        />
                        <span>{{
                          `${file.nama}.${
                            file.tipe_file ? file.tipe_file : "pdf"
                          }`
                        }}</span>
                      </div>
                      <a target="_blank" :href="file.file">
                        <ImageResponsive
                          imageUrl="suara-penentu/unduh.svg"
                          :fromUrl="false"
                          :fromApi="false"
                          :height="'24'"
                          width="24"
                          class="unduh-img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="shadow-arrow shadow-left prev-lhkpn">
                  <div class="icon-wrapper">
                    <Icons name="arrow-left" color="#fff" class="arrow prev" />
                  </div>
                </div>
                <div class="shadow-arrow shadow-right next-lhkpn">
                  <div class="icon-wrapper">
                    <Icons name="arrow-right" color="#fff" class="arrow next" />
                  </div>
                </div>
              </div>
            </div>
            <div class="list-document">
              <h3>Daftar Dokumen Riwayat Hidup</h3>
              <p v-if="true">Tidak ada data.</p>
              <div v-else class="position-relative">
                <div class="swiper-card" v-swiper:swiperCv="swiperOptions.cv">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide files"
                      v-for="(file, index) in 6"
                      :key="`history-${index}`"
                    >
                      <div class="file-name">
                        <ImageResponsive
                          imageUrl="suara-penentu/pdf.png"
                          :fromUrl="false"
                          :fromApi="false"
                          :height="'40'"
                          width="40"
                          class="pdf-img"
                        />
                        <span>{{ `file-${index}.pdf` }}</span>
                      </div>
                      <ImageResponsive
                        imageUrl="suara-penentu/unduh.svg"
                        :fromUrl="false"
                        :fromApi="false"
                        :height="'24'"
                        width="24"
                        class="unduh-img"
                      />
                    </div>
                  </div>
                </div>
                <div class="shadow-arrow shadow-left prev-cv">
                  <div class="icon-wrapper">
                    <Icons name="arrow-left" color="#fff" class="arrow prev" />
                  </div>
                </div>
                <div class="shadow-arrow shadow-right next-cv">
                  <div class="icon-wrapper">
                    <Icons name="arrow-right" color="#fff" class="arrow next" />
                  </div>
                </div>
              </div>
            </div>
            <div class="list-document">
              <h3>Dokumen Lain</h3>
              <p v-if="true">Tidak ada data.</p>
              <div v-else class="position-relative">
                <div
                  class="swiper-card"
                  v-swiper:swiperOther="swiperOptions.other"
                >
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide files"
                      v-for="(file, index) in 6"
                      :key="`history-${index}`"
                    >
                      <div class="file-name">
                        <ImageResponsive
                          imageUrl="suara-penentu/pdf.png"
                          :fromUrl="false"
                          :fromApi="false"
                          :height="'40'"
                          width="40"
                          class="pdf-img"
                        />
                        <span>{{ `file-${index}.pdf` }}</span>
                      </div>
                      <ImageResponsive
                        imageUrl="suara-penentu/unduh.svg"
                        :fromUrl="false"
                        :fromApi="false"
                        :height="'24'"
                        width="24"
                        class="unduh-img"
                      />
                    </div>
                  </div>
                </div>
                <div class="shadow-arrow shadow-left prev-other">
                  <div class="icon-wrapper">
                    <Icons name="arrow-left" color="#fff" class="arrow prev" />
                  </div>
                </div>
                <div class="shadow-arrow shadow-right next-other">
                  <div class="icon-wrapper">
                    <Icons name="arrow-right" color="#fff" class="arrow next" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="korupsi">
            <h2 class="title-column">Indikasi Korupsi</h2>
            <span v-if="response.data.keterlibatan_korupsi.length === 0"
              >Tidak ada data.</span
            >
            <div v-else class="position-relative">
              <div
                class="swiper-card"
                v-swiper:swiperKorupsi="swiperOptions.korupsi"
              >
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide korupsi"
                    v-for="(item, index) in response.data.keterlibatan_korupsi"
                    :key="`korupsi-${index}`"
                  >
                    <h4>{{ item.judulisu }}</h4>
                    <a target="_blank" :href="item.sumber_informasi">{{
                      item.sumber_informasi
                    }}</a>
                  </div>
                </div>
              </div>
              <div class="shadow-arrow shadow-left prev-korupsi">
                <div class="icon-wrapper">
                  <Icons name="arrow-left" color="#fff" class="arrow prev" />
                </div>
              </div>
              <div class="shadow-arrow shadow-right next-korupsi">
                <div class="icon-wrapper">
                  <Icons name="arrow-right" color="#fff" class="arrow next" />
                </div>
              </div>
            </div>
          </section>
          <section id="articles">
            <div class="primary">
              <div class="article-reguler">
                <div
                  class="article-reguler__artikel-terkait"
                  v-show="
                    artikel_terkait.items && artikel_terkait.items.length > 0
                  "
                >
                  <div class="title-wrapper">
                    <TitleSection title="ARTIKEL TERKAIT" />
                  </div>
                  <div class="list-card-artikel-terkait">
                    <ShimmerCardHome
                      v-show="artikel_terkait.loading"
                      v-for="(item, index) in 4"
                      :key="`artikel_terkait-rekam-jejak-shimmer-${index}`"
                      class="swiper-slide"
                    />
                    <CardsCardTwo
                      v-show="!artikel_terkait.loading"
                      v-for="(item, index) in artikel_terkait.items"
                      :key="`artikel-terkait-rekam-jejak-${index}`"
                      :image="
                        item.thumbnail && item.thumbnail.medium
                          ? item.thumbnail.medium
                          : 'https://narasi.tv/storage/images/dummy.png'
                      "
                      :imageFromUrl="true"
                      :duration="item.timeVideo ? item.timeVideo : '00:00'"
                      :channel="item.channel.title"
                      :channelSlug="item.channel.slug"
                      :programSlug="
                        item.channel.program ? item.channel.program.slug : ''
                      "
                      :category="
                        item.category ? item.category.title : 'category'
                      "
                      :categorySlug="
                        item.category ? item.category.slug : 'category'
                      "
                      :title="item.title"
                      :isText="item.isText"
                      :withDate="true"
                      :date="item.publishDate"
                      :mobileCardDouble="true"
                      :to="
                        item.isText
                          ? {
                              name: `read-channel-slug`,
                              params: {
                                channel: item.channel.slug,
                                slug: item.slug,
                              },
                              query: {
                                ref: 'artikel-terkait',
                              },
                            }
                          : {
                              name: 'video-channel-slug',
                              params: {
                                channel: item.channel.slug,
                                slug: item.slug,
                              },
                              query: {
                                ref: 'artikel-terkait',
                              },
                            }
                      "
                      class="swiper-slide"
                    />
                  </div>
                </div>
                <div
                  class="article-reguler__video-terkait"
                  v-show="video_terkait.items && video_terkait.items.length > 0"
                >
                  <div class="title-wrapper">
                    <TitleSection title="VIDEO TERKAIT" />
                  </div>
                  <div class="list-card-video-terkait">
                    <div
                      v-swiper:mySwiperVideoTerkaitRekamJejak="
                        swiperOptions.video_terkait
                      "
                    >
                      <div class="swiper-wrapper">
                        <ShimmerCardHome
                          v-show="video_terkait.loading"
                          v-for="(item, index) in 4"
                          :key="`video-terkait-rekam-jejak-shimmer-${index}`"
                          class="swiper-slide"
                        />
                        <CardsCardTwo
                          v-show="!video_terkait.loading"
                          v-for="(item, index) in video_terkait.items"
                          :to="
                            item.isText
                              ? {
                                  name: `read-channel-slug`,
                                  params: {
                                    channel: item.channel.slug,
                                    slug: item.slug,
                                  },
                                  query: {
                                    ref: 'video-terkait',
                                  },
                                }
                              : {
                                  name: 'video-channel-slug',
                                  params: {
                                    channel: item.channel.slug,
                                    slug: item.slug,
                                  },
                                  query: {
                                    ref: 'video-terkait',
                                  },
                                }
                          "
                          :key="`video-terkait-rekam-jejak-${index}`"
                          :image="
                            item.thumbnail && item.thumbnail.medium
                              ? item.thumbnail.medium
                              : 'https://narasi.tv/storage/images/dummy.png'
                          "
                          :imageFromUrl="true"
                          :duration="item.timeVideo ? item.timeVideo : '00:00'"
                          :channel="item.channel.title"
                          :channelSlug="item.channel.slug"
                          :programSlug="
                            item.channel.program
                              ? item.channel.program.slug
                              : ''
                          "
                          :category="
                            item.category ? item.category.title : 'category'
                          "
                          :categorySlug="
                            item.category ? item.category.slug : 'category'
                          "
                          :title="item.title"
                          :isText="item.isText"
                          class="swiper-slide"
                        />
                      </div>
                      <div class="swiper-pagination"></div>
                    </div>
                    <div class="shadow-arrow shadow-left prev-video-terkait">
                      <Icons name="arrow-left" class="arrow prev" />
                    </div>
                    <div class="shadow-arrow shadow-right next-video-terkait">
                      <Icons name="arrow-right" class="arrow next" />
                    </div>
                  </div>
                </div>
                <div class="article-reguler__narasi-academy">
                  <div class="title-wrapper">
                    <TitleSection title="NARASI ACADEMY" />
                  </div>
                  <div class="list-card-narasi-academy">
                    <div
                      v-swiper:mySwiperIkutiKelasRekamJejak="
                        swiperOptions.ikuti_kelas
                      "
                    >
                      <div class="swiper-wrapper">
                        <ShimmerListCard v-if="ikutiKelas.loading" />
                        <CardsCardTwentyThree
                          class="swiper-slide"
                          v-for="(card, index) in ikutiKelas.items"
                          v-show="ikutiKelas.items.length > 0"
                          :key="'card-ikuti-kelas' + index"
                          :image="
                            card.thumbnail && card.thumbnail.large
                              ? card.thumbnail.large
                              : 'https://narasi.tv/storage/images/dummy.png'
                          "
                          :imageFromUrl="true"
                          :categories="card.category"
                          :classType="card.classType"
                          :title="card.title"
                          :slug="card.slug"
                          :description="card.description"
                          :datetime="card.startDate"
                          :isSoon="card.isSoon"
                          :price="card.price"
                          :discountPrice="card.discountPrice"
                          :discount="card.discount"
                          :to="{
                            name: 'academy-detail-slug',
                            params: {
                              slug: card.slug,
                            },
                          }"
                          :startEvent="card.startDate"
                          :endEvent="card.endDate"
                          :startRegis="card.startRegistrationDate"
                          :endRegis="card.endRegistrationDate"
                        />
                      </div>
                      <div class="swiper-pagination"></div>
                    </div>
                    <div
                      class="shadow-arrow shadow-left prev-ikuti_kelas"
                      v-show="ikutiKelas.items.length > 3"
                    >
                      <Icons
                        name="arrow-left"
                        class="arrow prev"
                        v-show="ikutiKelas.items.length > 3"
                      />
                    </div>
                    <div
                      class="shadow-arrow shadow-right next-ikuti_kelas"
                      v-show="ikutiKelas.items.length > 3"
                    >
                      <Icons
                        name="arrow-right"
                        class="arrow next"
                        v-show="ikutiKelas.items.length > 3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="sidebar">
          <div class="playlist-article-reguler">
            <div class="playlist-article-reguler__populer">
              <!-- ADS START -->
              <AdsHorizontalFixed
                class="pb-3"
                width="300px"
                height="250px"
                id="placement_sidebase_article_detail"
              >
              </AdsHorizontalFixed>
              <!-- ADS END -->
              <div class="header">
                <TitleSection
                  title="TERPOPULER"
                  size="medium"
                  class="header__title"
                />
              </div>
              <div class="body">
                <transition-group
                  name="list"
                  tag="div"
                  class="list-video-populer"
                >
                  <ShimmerCardHomeLandscapeSmall
                    v-for="item in 5"
                    :key="`populer-shimmer${item}`"
                    v-show="populer.loading"
                  />
                  <CardsCardEight
                    v-show="!populer.loading"
                    v-for="item in populer.items"
                    :key="`populer-${item.id}`"
                    :image="
                      item.thumbnail
                        ? item.thumbnail.large
                        : 'https://narasi.tv/storage/images/dummy.png'
                    "
                    :imageFromUrl="true"
                    :duration="item.timeVideo ? item.timeVideo : '00:00'"
                    :category="item.category ? item.category.title : ''"
                    :categorySlug="item.category ? item.category.slug : ''"
                    :channel="item.channel ? item.channel.title : ''"
                    :channelSlug="item.channel.slug"
                    :programSlug="
                      item.channel.program ? item.channel.program.slug : ''
                    "
                    :title="item.title"
                    :isText="item.isText"
                    :to="
                      item.isText
                        ? {
                            name: `read-channel-slug`,
                            params: {
                              channel: item.channel.slug,
                              slug: item.slug,
                            },
                            query: {
                              ref: 'terpopuler-detail',
                            },
                          }
                        : {
                            name: 'video-channel-slug',
                            params: {
                              channel: item.channel.slug,
                              slug: item.slug,
                            },
                            query: {
                              ref: 'terpopuler-detail',
                            },
                          }
                    "
                    class="card-video-populer"
                  />
                </transition-group>
                <div
                  class="button-show-more"
                  :class="{ 'mt-5': populer.loadingShowMore }"
                >
                  <span
                    v-show="
                      !populer.loadingShowMore &&
                      populer.items.length < populer.totalItem
                    "
                    @click="nextArticle"
                    >LIHAT LEBIH BANYAK</span
                  >
                  <div
                    v-show="populer.loadingShowMore"
                    class="dot-spin m-auto"
                  ></div>
                </div>
              </div>
            </div>
            <AdsHorizontalFixedProps
              class="mt-3"
              width="315px"
              height="290px"
              id="props_placement_before_populer"
            >
            </AdsHorizontalFixedProps>
            <AdsArtikelTeksArticlePageStickyAds />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import config from "/config";
import { mapGetters } from "vuex";
import ImageResponsive from "../../../components/ImageResponsive.vue";
import Icons from "../../../components/Icons.vue";
import TitleSection from "../../../components/TitleSection.vue";
import ShimmerCardHome from "../../../components/shimmer/CardHome.vue";
import CardsCardTwo from "../../../components/cards/CardTwo.vue";
import ShimmerListCard from "../../../components/shimmer/ListCard.vue";
import CardsCardTwentyThree from "../../../components/cards/CardTwentyThree.vue";
import AdsHorizontalFixed from "../../../components/ads/HorizontalFixed.vue";
import ShimmerCardHomeLandscapeSmall from "../../../components/shimmer/CardHomeLandscapeSmall.vue";
import CardsCardEight from "../../../components/cards/CardEight.vue";
import AdsHorizontalFixedProps from "../../../components/ads/HorizontalFixedProps.vue";
import AdsArtikelTeksArticlePageStickyAds from "../../../components/ads/artikelTeks/ArticlePageStickyAds.vue";

export default {
  components: {
    AdsArtikelTeksArticlePageStickyAds,
    AdsHorizontalFixedProps,
    CardsCardEight,
    ShimmerCardHomeLandscapeSmall,
    AdsHorizontalFixed,
    CardsCardTwentyThree,
    ShimmerListCard,
    CardsCardTwo,
    ShimmerCardHome,
    ImageResponsive,
    Icons,
    TitleSection,
  },
  async asyncData({ app, params, query, store, route, error, redirect }) {
    redirect(301, "/");
    try {
      store.commit("config/setLoading", true);

      const response = await app.$axios.$get(
        `${config.CORE_SERVICE_API}thirdparty/rekam_jejak/${params.slug}`
      );

      store.commit("seoWithAmp/SET_SEO", {
        title: `Rekam Jejak ${response.data.nama}`,
        desc: response.data.highlight
          ? response.data.highlight.replace(/(<([^>]+)>)/gi, "")
          : `Rekam jejak ${response.data.nama}`,
        image: response.data.gambar
          ? response.data.gambar
          : "https://narasi.tv/storage/images/dummy.png",
        url: `${config.BASE_URL}${route.path}`,
        path: `${config.BASE_URL}${route.path}`,
        ampPath: `${config.BASE_URL}/amp${route.path}`,
      });

      const riwayat = {
        Pekerjaan: {
          logo: "work.svg",
          items: response.data.riwayatpekerjaan.map((d) => {
            return {
              value: `${d.jabatan} ${d.nama_perusahaan.nama} (Tahun: ${d.tahun})`,
            };
          }),
        },
        Organisasi: {
          logo: "organization.svg",
          items: response.data.data_organisasi.map((d) => {
            return {
              value: `${d.jabatan} ${d.organisasi.nama}`,
            };
          }),
        },
        "Partai Politik": {
          logo: "politic.svg",
          items: response.data.karir_parpol.map((d) => {
            return {
              value: `${d.parpol.nama} (Tahun: ${d.tahun_mulai})`,
            };
          }),
        },
        Pemilu: {
          logo: "pemilu.svg",
          items: response.data.ikut_pemilu.map((d) => {
            return {
              value: `Dapil ${d.dapil.nama} (Nomor Urut: ${d.nomor_urut} - ${d.partai_pengusung[0].kode})`,
            };
          }),
        },
        Bisnis: {
          logo: "bisnis.svg",
          items: response.data.riwayat_bisnis.map((d) => {
            return {
              value: `${d.perusahaan.nama} (Jenis Usaha: ${d.perusahaan.jenis_usaha})`,
            };
          }),
        },
        Pendidikan: {
          logo: "work.svg",
          items: response.data.data_pendidikan.map((d) => {
            return {
              value: `${d.pendidikanid.nama} (Tahun: ${d.tahun})`,
            };
          }),
        },
      };

      response.data.pekerjaan_legislatif.map((d) => {
        riwayat["Pekerjaan"].items.push({
          value: `${d.jabatan} ${d.perwakilan_rakyat.ruang_lingkup} (Tahun: ${d.tahun_pelantikan})`,
        });
      });

      response.data.pekerjaan_pemerintahan.map((d) => {
        riwayat["Pekerjaan"].items.push({
          value: `${d.jabatan} ${d.instansi_pemerintah.nama} (Sampai Tahun: ${d.tahun_selesai})`,
        });
      });

      store.commit("config/setLoading", false);

      return { response, riwayat };
    } catch (err) {
      store.commit("config/setLoading", false);
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  head() {
    return {
      ...this.headReturn,
    };
  },
  data() {
    return {
      mappingSaurada: {
        3: "Anak",
        1: "Suami",
        10: "Lainnya",
        11: "Bapak",
        12: "Ibu",
        9: "Saudara",
        2: "Istri",
      },
      swiperOptions: {
        history: {
          freeMode: true,
          autoHeight: true,
          calculateHeight: true,
          pagination: false,
          navigation: {
            prevEl: ".prev-history",
            nextEl: ".next-history",
          },
          breakpoints: {
            1023: {
              slidesPerView: 3.3,
              spaceBetween: 8,
            },
            320: {
              slidesPerView: 1.7,
              spaceBetween: 8,
            },
          },
        },
        lhkpn: {
          freeMode: true,
          autoHeight: true,
          calculateHeight: true,
          pagination: false,
          navigation: {
            prevEl: ".prev-lhkpn",
            nextEl: ".next-lhkpn",
          },
          breakpoints: {
            1023: {
              slidesPerView: 3.3,
              spaceBetween: 8,
            },
            320: {
              slidesPerView: 1.7,
              spaceBetween: 8,
            },
          },
        },
        cv: {
          freeMode: true,
          autoHeight: true,
          calculateHeight: true,
          pagination: false,
          navigation: {
            prevEl: ".prev-cv",
            nextEl: ".next-cv",
          },
          breakpoints: {
            1023: {
              slidesPerView: 3.3,
              spaceBetween: 8,
            },
            320: {
              slidesPerView: 1.7,
              spaceBetween: 8,
            },
          },
        },
        other: {
          freeMode: true,
          autoHeight: true,
          calculateHeight: true,
          pagination: false,
          navigation: {
            prevEl: ".prev-other",
            nextEl: ".next-other",
          },
          breakpoints: {
            1023: {
              slidesPerView: 3.3,
              spaceBetween: 8,
            },
            320: {
              slidesPerView: 1.7,
              spaceBetween: 8,
            },
          },
        },
        korupsi: {
          freeMode: true,
          autoHeight: true,
          calculateHeight: true,
          pagination: false,
          navigation: {
            prevEl: ".prev-korupsi",
            nextEl: ".next-korupsi",
          },
          breakpoints: {
            1023: {
              slidesPerView: 3.3,
              spaceBetween: 8,
            },
            320: {
              slidesPerView: 1.7,
              spaceBetween: 8,
            },
          },
        },
        video_terkait: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          observer: true,
          observeParents: true,
          watchSlidesVisibility: true,
          navigation: {
            prevEl: ".prev-video-terkait",
            nextEl: ".next-video-terkait",
          },
          breakpoints: {
            1023: {
              slidesPerView: 2.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 8,
            },
          },
        },
        ikuti_kelas: {
          freeMode: true,
          autoHeight: true,
          calculateHeight: true,
          pagination: false,
          navigation: {
            prevEl: ".prev-ikuti_kelas",
            nextEl: ".next-ikuti_kelas",
          },
          breakpoints: {
            1023: {
              slidesPerView: 2.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.2,
              spaceBetween: 12,
            },
          },
        },
      },
      limit: 8,
    };
  },
  computed: {
    ...mapGetters({ headReturn: "seoWithAmp/headReturn" }),
    ...mapState({
      artikel_terkait: (state) => {
        return state.articles.artikel_terkait;
      },
      video_terkait: (state) => {
        return state.articles.video_terkait;
      },
      populer: (state) => {
        return state.videos.populer;
      },
      ikutiKelas: (state) => {
        return state.academies.academyClass;
      },
    }),
  },
  methods: {
    nextArticle() {
      this.limit = this.limit + 5;
      this.$store.dispatch("videos/getVideoPopuler", {
        limit: this.limit,
      });
    },
  },
  mounted() {
    this.$store.dispatch("videos/getVideoPopuler", { limit: this.limit });
    this.$store.dispatch(
      "articles/getArtikelTerkait",
      "904d19ac-7373-4965-a2c2-0aedde912bf1"
    );
    this.$store.dispatch(
      "articles/getVideoTerkait",
      "904d19ac-7373-4965-a2c2-0aedde912bf1"
    );
    this.$store.dispatch("academies/getClass");
  },
};
</script>

<style lang="scss" scoped>
.rekam-jejak {
  padding: 24px 0;
  .main-rekam-jejak {
    display: flex;
    width: 100%;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
    }
    .main {
      width: 70%;
      padding-right: 30px;
      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding: 0;
      }
    }
    .sidebar {
      width: 30%;
      .playlist-article-reguler {
        width: 100%;
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
        &__populer {
          @media only screen and (max-width: 1024px) {
            padding: 32px 20px;
            border-top: 1px solid lightgray;
          }
          .header {
            margin-bottom: 24px;
          }
          .body {
            .list-enter-active {
              transition: all 1s;
            }
            .list-enter /* .list-leave-active below version 2.1.8 */ {
              opacity: 0;
            }
            .list-video-populer {
              margin-bottom: 24px;
              ::v-deep .card-eight {
                &:first-child {
                  padding-top: 0;
                }
              }
              .card-video-populer {
                width: 100%;
                &:nth-child(6) {
                  padding-top: 0;
                }
              }
            }
            .button-show-more {
              text-align: center;
              span {
                cursor: pointer;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #4a25aa;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 700;
                color: #4a25aa;
                padding: 0 20px;
                @media only screen and (max-width: 1024px) {
                  height: 40px;
                  &:hover {
                    color: #4a25aa !important;
                  }
                }
                @media only screen and (min-width: 1024px) {
                  &:hover {
                    color: #8166c8 !important;
                    border-color: #8166c8 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  section .title-column {
    color: #000;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    padding: 16px 0;
  }

  .swiper-card {
    .swiper-wrapper {
      .swiper-slide {
        cursor: pointer;
        background: #fff;
        border-radius: 6px;

        .photo-caleg {
          height: 75px;

          ::v-deep img {
            object-fit: cover;
          }
        }

        .name {
          height: 36px;
          padding: 6px;
          background: #49898a;
          font-family: "Varela Round", sans-serif;
          font-size: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          color: #fff;
        }
      }
    }
  }

  .shadow-arrow {
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 35px;
    height: 100%;
    padding: 0;
    z-index: 2;
    opacity: 1;
    transition: ease-in-out 0.5s;

    @media only screen and (max-width: 1024px) {
      display: none;
    }

    .icon-wrapper {
      width: 35px;
      height: 35px;
      background: #49898a;
      transform: translateY(-50%);
      top: 50%;
      position: absolute;
      border-radius: 100%;

      svg {
        pointer-events: visible;
        width: 100%;
        height: 100%;
      }
    }

    &.shadow-left {
      left: 8px;
    }

    &.shadow-right {
      right: 8px;
    }

    &.swiper-button-disabled {
      opacity: 0;
      z-index: 0;
    }
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: auto;

    @media only screen and (max-width: 1024px) {
      display: none;
    }

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }

    &.hide {
      display: none;
    }
  }

  #personal-info {
    .header {
      display: flex;
      gap: 8px;
      align-items: center;

      span {
        font-size: 12px;
        color: #49898a;
        font-family: "Varela Round", sans-serif;
      }

      .logo-rekam-jejak {
        height: 24px;
        width: 104px;
      }
    }

    .profile {
      margin-top: 8px;
      display: flex;
      gap: 24px;

      @media only screen and (max-width: 1024px) {
        gap: 16px;
      }

      .profile-img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border: 2px solid #d9d9d9;
        border-radius: 16px;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          max-width: 90px;
          max-height: 90px;
        }
      }

      .info {
        h1 {
          color: #49898a;
          font-family: Roboto;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          @media only screen and (max-width: 1024px) {
            font-size: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            pointer-events: none;
          }
        }

        .parpol {
          display: flex;
          gap: 16px;
          align-items: center;
          @media only screen and (max-width: 1024px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
          }

          span {
            color: #000;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }

          .partai {
            border-right: 1px solid #d9d9d9;
            padding-right: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            @media only screen and (max-width: 1024px) {
              gap: 4px;
            }

            .pengusung {
              display: flex;
              gap: 8px;
              align-items: center;
            }

            .parpol-img {
              width: 32px;
              height: 32px;
              object-fit: cover;
              @media only screen and (max-width: 1024px) {
                height: 100%;
              }
            }
          }

          .daerah {
            display: flex;
            flex-direction: column;
            gap: 8px;
            @media only screen and (max-width: 1024px) {
              gap: 4px;
            }
          }
        }
      }
    }

    .identitas {
      padding: 24px 0;
      display: flex;
      gap: 24px;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
      }

      .basic {
        border-radius: 8px;
        background: #d9d9d9;
        height: 100%;

        .info {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 150px;
          padding: 16px;
          @media only screen and (max-width: 1024px) {
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
          }

          .column {
            @media only screen and (max-width: 1024px) {
              width: 30%;
            }
            label {
              display: block;
              margin-bottom: 4px;
              color: #000;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
            }

            span {
              display: block;
              color: #000;
              font-family: Roboto;
              font-size: 12px;
            }

            .social-media {
              img {
                width: 24px;
                height: 24px;
                object-fit: cover;
              }
            }
          }
        }
      }

      .summary {
        h2 {
          color: #051c2c;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
        }

        .content {
          color: #051c2c;
          font-family: Roboto;
          font-size: 16px;
        }
      }
    }
  }

  #history {
    .swiper-card {
      padding: 16px 0;

      .history {
        border-radius: 8px;
        background: #d9d9d9;
        padding: 16px;
        height: 236px;
        min-height: 236px;
        overflow-x: scroll;
      }

      .title-swiper {
        display: flex;
        gap: 8px;
        margin-bottom: 20px;

        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }

        span {
          color: #49898a;
          font-family: Roboto;
          font-size: 16px;
        }
      }

      .content-swiper {
        ul {
          padding-left: 16px;
          color: #000;
          font-family: Roboto;
          font-size: 14px;
          li {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  #family {
    .list-family {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 16px;
      @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .family-item {
        display: flex;
        gap: 8px;

        .profile-img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 100%;
        }

        .figure {
          .name {
            color: #000;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }

          .role {
            color: #000;
            font-family: Roboto;
            font-size: 12px;
          }
        }
      }
    }
  }

  #report {
    .list-reports {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .report-item {
        border-radius: 8px;
        border: 1px solid #f1f1f1;
        background: #fff;
        padding: 16px;

        .year-patuh {
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            color: #49898a;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
          }

          .status-img {
            height: 24px;
            width: fit-content;
          }
        }

        .list-report-item {
          color: #000;
          font-family: Roboto;
          font-size: 14px;
          margin-top: 8px;
          padding-left: 16px;
        }
      }
    }
  }

  #issue {
    .list-issue {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .issue-item {
        border-radius: 8px;
        border: 1px solid #f1f1f1;
        background: #fff;
        padding: 16px;

        h3 {
          color: #000;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }

        p {
          color: #000;
          font-family: Roboto;
          font-size: 14px;
          margin-bottom: 8px;
        }

        a {
          overflow: hidden;
          color: #ababab;
          text-overflow: ellipsis;
          font-family: Roboto;
          font-size: 12px;

          &:hover {
            color: #ababab !important;
          }
        }
      }
    }
  }

  #document {
    .title-column {
      padding-bottom: 0 !important;
    }

    .list-document {
      h3 {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        padding: 8px 0;
      }

      .files {
        border-radius: 8px;
        border: 1px solid #f1f1f1;
        background: #fff;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .file-name {
          display: flex;
          align-items: center;
          gap: 8px;

          .pdf-img {
            width: 40px;
            height: 40px;
            object-fit: cover;
          }

          span {
            color: #000;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
          }
        }

        .unduh-img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }
    }
  }

  #korupsi {
    .korupsi {
      border-radius: 8px;
      border: 1px solid #f1f1f1;
      background: #fff;
      padding: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: inline-block;

      h4 {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }

      a {
        color: #ababab;
        font-family: Roboto;
        font-size: 12px;

        &:hover {
          color: #ababab !important;
        }
      }
    }
  }

  #articles {
    padding: 24px 0;

    .primary {
      width: 100%;

      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 0;
      }

      .article-reguler {
        padding-right: 30px;

        @media only screen and (max-width: 1024px) {
          width: 100%;
          padding: 0;
        }

        &__artikel-terkait {
          margin-bottom: 48px;

          @media only screen and (max-width: 1024px) {
            margin: 32px 0;
            padding: 32px 20px 0;
            border-top: 1px solid lightgray;
          }

          .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            .lihat-semua {
              font-size: 16px;
              font-weight: 500;
              color: #4a25aa;
              text-decoration: underline !important;

              &:hover {
                color: #4a25aa !important;
              }

              @media only screen and (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }

          .list-card-artikel-terkait {
            position: relative;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 16px;
            grid-row-gap: 16px;

            @media only screen and (max-width: 1024px) {
              grid-template-columns: repeat(2, 1fr);
              grid-column-gap: 8px;
              grid-row-gap: 8px;
            }
          }
        }

        &__video-terkait {
          @media only screen and (max-width: 1024px) {
            margin: 0;
            padding: 32px 20px 0;
            border-top: 1px solid lightgray;
          }

          .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            .lihat-semua {
              font-size: 16px;
              font-weight: 500;
              color: #4a25aa;
              text-decoration: underline !important;

              &:hover {
                color: #4a25aa !important;
              }

              @media only screen and (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }

          .list-card-video-terkait {
            position: relative;
            overflow: hidden;

            .shadow-arrow {
              pointer-events: none;
              position: absolute;
              top: 0;
              width: 100px;
              height: 100%;
              padding: 0;
              background: rgb(0, 0, 0);
              z-index: 2;
              opacity: 1;
              transition: ease-in-out 0.5s;

              @media only screen and (max-width: 1024px) {
                display: none;
              }

              svg {
                pointer-events: visible;
              }

              &.shadow-left {
                left: 0;
                background: linear-gradient(
                  to left,
                  hsla(0, 0%, 100%, 0),
                  #f1f1f1
                );
              }

              &.shadow-right {
                right: 0;
                background: linear-gradient(
                  to right,
                  hsla(0, 0%, 100%, 0),
                  #f1f1f1
                );
              }

              &.swiper-button-disabled {
                opacity: 0;
                z-index: 0;
              }
            }

            .arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
              width: auto;

              @media only screen and (max-width: 1024px) {
                display: none;
              }

              &.prev {
                left: 0;
              }

              &.next {
                right: 0;
              }

              &.hide {
                display: none;
              }
            }

            .swiper-container {
              @media only screen and (max-width: 1024px) {
                padding: 20px !important;
                margin: -20px !important;
              }

              .swiper-wrapper {
                height: 100% !important;
              }
            }
          }
        }

        &__narasi-academy {
          margin-bottom: 48px;

          @media only screen and (max-width: 1024px) {
            margin: 0 0 32px;
            padding: 32px 20px 0;
            border-top: 1px solid lightgray;
          }

          .title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            .lihat-semua {
              font-size: 16px;
              font-weight: 500;
              color: #4a25aa;
              text-decoration: underline !important;

              &:hover {
                color: #4a25aa !important;
              }

              @media only screen and (max-width: 1024px) {
                font-size: 12px;
              }
            }
          }

          .list-card-narasi-academy {
            position: relative;
            overflow: hidden;

            .shadow-arrow {
              pointer-events: none;
              position: absolute;
              top: 0;
              width: 100px;
              height: 100%;
              padding: 0;
              background: rgb(0, 0, 0);
              z-index: 2;
              opacity: 1;
              transition: ease-in-out 0.5s;

              @media only screen and (max-width: 1024px) {
                display: none;
              }

              svg {
                pointer-events: visible;
              }

              &.shadow-left {
                left: 0;
                background: linear-gradient(
                  to left,
                  hsla(0, 0%, 100%, 0),
                  #f1f1f1
                );
              }

              &.shadow-right {
                right: 0;
                background: linear-gradient(
                  to right,
                  hsla(0, 0%, 100%, 0),
                  #f1f1f1
                );
              }

              &.swiper-button-disabled {
                opacity: 0;
                z-index: 0;
              }
            }

            .arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 2;
              width: auto;

              @media only screen and (max-width: 1024px) {
                display: none;
              }

              &.prev {
                left: 0;
              }

              &.next {
                right: 0;
              }

              &.hide {
                display: none;
              }
            }

            .swiper-container {
              @media only screen and (max-width: 1024px) {
                padding: 20px !important;
                margin: -20px !important;
              }

              .swiper-wrapper {
                height: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
