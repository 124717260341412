<template>
  <div class="widget-wrapper">
    <QuickCountWidget
      :intervalProvider="{
        delay: 10000,
      }"
      :intervalZone="{
        delay: 3000,
      }"
      :intervalParpol="{
        delay: 3000,
      }"
      :linkSelengkapnya="null"
      :showKpu="true"
      :showTable="true"
      :showProvider="true"
      :showParpol="true"
    />
  </div>
</template>

<script>
import QuickCountWidget from "../../components/QuickCountWidget.vue";
export default {
  asyncData({ redirect }) {
    redirect(301, "/");
  },
  layout: "quick-count",
  components: {
    QuickCountWidget,
  },
  head() {
    return {
      title: "Widget Quick Count",
      meta: [
        {
          name: "robots",
          content: "noindex",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.widget-wrapper {
  padding: 16px 0;
}
</style>
