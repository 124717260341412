<template>
  <div class="suara-penentu">
    <ModalInstagram
      :reel_id="reel.reel_id"
      :show="showIG"
      @hide-modal-ig="showIG = false"
    />
    <div class="chatbot-wrapper" :class="{ active: isOpenChatbot }">
      <div
        id="chatbox-support"
        class="chatbox-support"
        :class="{ 'chatbox-active': isOpenChatbot }"
      >
        <div class="close-wrapper" @click="chatbot()">
          <Icons name="close-modal-login" class="close-chatbot" />
        </div>
        <iframe
          src="https://app.smojo.org/farreladelm/suara-penentu-bot-development"
          title="Chatbot dari Narasi TV"
          class="chatbot"
        ></iframe>
      </div>
      <div class="notif" @click="chatbot()">
        <span>1</span>
        <VLazyImage
          src="/storage/images/suara-penentu/chat.svg"
          alt="Chatbot"
        />
      </div>
    </div>
    <TopAds />
    <div class="quick-count-wrapper">
      <div class="container">
        <div class="ads-main">
          <Billboard />
        </div>
        <QuickCount
          :linkSelengkapnya="null"
          :showKpu="true"
          :showTable="true"
          :showProvider="true"
          :showParpol="true"
        />
      </div>
    </div>
    <section class="countdown">
      <div class="container">
        <div class="logo">
          <VLazyImage src="/storage/images/suara-penentu/logo.svg" alt="" />
        </div>
        <div class="desc">
          <p>
            Lebih dari 50% pemilih dalam Pemilu 2024 diisi oleh anak muda
            berusia di bawah 40 tahun. Ini saatnya anak muda menentukan masa
            depan Indonesia.
          </p>
        </div>
      </div>
    </section>
    <section class="highlight">
      <div class="container">
        <div class="wrapper">
          <div
            class="slider-highlight-container"
            instance-name="highlight"
            v-swiper:highlight="swiperOptions.highlightSlider"
          >
            <div class="swiper-wrapper slider-highlight">
              <ShimmerHighlight
                v-if="$store.state.suaraPenentu.highlights.loading"
              />
              <Link
                v-show="!$store.state.suaraPenentu.highlights.loading"
                :to="highlight.slug"
                class="swiper-slide slider-highlight-item"
                v-for="(highlight, index) in this.$store.state.suaraPenentu
                  .highlights.items"
                :key="`highlight-${index}`"
              >
                <div class="position-relative">
                  <VLazyImage
                    class="hero-img"
                    :src="`https://images.narasi.tv/preset:sharp/resize:fill:312:174:1/gravity:ce/plain/${highlight.thumbnail}@webp`"
                    alt=""
                  />
                  <span class="time-video" v-show="highlight.type !== 'read'">{{
                    highlight.timeVideo
                  }}</span>
                </div>
                <div class="description">
                  <h2 class="title">{{ highlight.title }}</h2>
                  <p class="short">{{ highlight.short }}</p>
                </div>
              </Link>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="video-info-pemilu">
      <div class="container">
        <div class="header">
          <TitleSection title="VIDEO INFO PEMILU" borderColor="#FF4800" />
          <!-- <Link to="/tags/komisi-pemilihan-umum">Lihat Semua</Link> -->
        </div>
        <div class="list-video-info-pemilu">
          <div v-swiper:mySwiperVideoInfoPemilu="swiperOptions.videoInfoPemilu">
            <div class="swiper-wrapper">
              <!-- <ShimmerKenaliMentor v-if="mentor.loading" /> -->
              <div
                class="video-info-pemilu-card swiper-slide"
                v-for="(item, i) in reels"
                :key="`video-info-pemilu-${i}`"
                @click="showModalIG(i)"
              >
                <ImageResponsive
                  :imageUrl="item.thumbnail"
                  width="230"
                  height="360"
                  class="video-info-pemilu-card-thumbnail"
                  alt="thumbnail-mentor"
                />
                <div class="video-info-pemilu-card-content">
                  <ImageResponsive
                    :imageUrl="item.profile_picture"
                    width="230"
                    height="360"
                    class="video-info-pemilu-card-profile-picture"
                    alt="profile-picture"
                  />
                  <p class="account">{{ item.account }}</p>
                  <Icons name="check" />
                </div>
                <Icons name="reels" class="icon-reels" />
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
          <div class="shadow-arrow shadow-left prev-kenali-mentor">
            <Icons name="arrow-left" class="arrow prev" />
          </div>
          <div class="shadow-arrow shadow-right next-kenali-mentor">
            <Icons name="arrow-right" class="arrow next" />
          </div>
        </div>
      </div>
    </section>
    <section class="rekam-jejak">
      <div class="container">
        <RekamJekak />
      </div>
    </section>

    <!-- <section class="news-kpu">
      <div class="container">
        <div class="header">
          <TitleSection title="KABAR KPU" color="white" borderColor="#FF4800" />
          <Link to="/tags/komisi-pemilihan-umum">Lihat Semua</Link>
        </div>
        <div
          class="slider-kpu-container"
          instance-name="kpu"
          v-swiper:kpu="swiperOptions.KpuSlider"
        >
          <div class="swiper-wrapper slider-kpu">
            <ShimmerSliderKpu
              v-if="$store.state.suaraPenentu.newsKpu.loading"
            />
            <Link
              v-show="!$store.state.suaraPenentu.newsKpu.loading"
              v-for="(kpu, index) in this.$store.state.suaraPenentu.newsKpu
                .items"
              :key="`kpu-${index}`"
              :to="kpu.slug"
              class="swiper-slide slider-kpu-item"
            >
              <div class="thumbnail">
                <VLazyImage
                  class="hero-img"
                  :src="`https://images.narasi.tv/preset:sharp/resize:fill:312:174:1/gravity:ce/plain/${kpu.thumbnail}@webp`"
                  alt=""
                />
                <span class="time-video" v-show="kpu.type !== 'read'">{{
                  kpu.timeVideo
                }}</span>
              </div>
              <h2 class="title">{{ kpu.title }}</h2>
            </Link>
          </div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </section> -->
    <section class="terkini-sidebar">
      <div class="container">
        <div class="wrapper">
          <div class="primary">
            <MiddleCenterAds />
            <TitleSection title="TERKINI" borderColor="#FF4800" />
            <div class="list-article-terkini">
              <template v-if="$store.state.suaraPenentu.terkini.loading">
                <ShimmerCardHomeLandscape
                  v-for="(item, index) in 5"
                  :key="index"
                />
              </template>
              <div
                v-show="!$store.state.suaraPenentu.terkini.loading"
                class="article-terkini"
                v-for="(article, index) in screen < 1000
                  ? $store.state.suaraPenentu.terkini.items.slice(0, 5)
                  : $store.state.suaraPenentu.terkini.items"
                :key="`list-article-terkini-${index}`"
              >
                <Link :to="article.slug">
                  <div class="position-relative">
                    <VLazyImage
                      :src="`https://images.narasi.tv/preset:sharp/resize:fill:312:174:1/gravity:ce/plain/${article.thumbnail}@webp`"
                      alt=""
                    />
                    <span class="time-video">{{
                      article.type === "read" ? "" : article.timeVideo
                    }}</span>
                  </div>
                  <h3>{{ article.title }}</h3>
                </Link>
              </div>
              <div
                class="button-show-more is-desktop"
                :class="{
                  'mt-5': $store.state.suaraPenentu.terkini.loadingShowMore,
                }"
              >
                <span
                  v-if="
                    pageArticles < $store.state.suaraPenentu.terkini.pages &&
                    !$store.state.suaraPenentu.terkini.loadingShowMore
                  "
                  @click="loadMoreTerkini"
                  >LIHAT LEBIH BANYAK</span
                >
                <div
                  v-if="$store.state.suaraPenentu.terkini.loadingShowMore"
                  class="dot-spin m-auto"
                ></div>
              </div>
            </div>
          </div>
          <div class="secondary">
            <MiddleSquareAds />
            <div class="sidebar">
              <TitleSection title="PANTAU PEMILU 2024" borderColor="#FF4800" />
              <p class="description">
                Sejak masa kampanye berjalan, Narasi mengumpulkan titik-titik
                aktivitas capres dan cawapres di seluruh Indonesia yang bisa
                dipantau melalui peta berikut.
              </p>
              <iframe
                src="https://www.google.com/maps/d/embed?mid=1Q6xxQGFLA6gBOjEs0CLRMfLoMcQc0Pc&amp;femb=1&amp;ll=-2.026891317529631%2C118.23853259392695&amp;z=4"
                width="640"
                height="480"
              ></iframe>
              <TitleSection title="TOP SUARA NETIZEN" borderColor="#FF4800" />
              <p class="description">
                Narasi menghimpun kata-kata yang sering disampaikan oleh netizen
                melalui fitur Chatbot Suara Penentu dan berikut hasilnya.
              </p>
              <div class="tagcloud">
                <vue-word-cloud
                  :words="this.$store.state.suaraPenentu.tagCloud"
                  :color="
                    ([, weight]) =>
                      weight > 10
                        ? '#00B9FF'
                        : weight > 5
                        ? '#FFB300'
                        : '#FFFFFF'
                  "
                  font-family="Varela Round"
                />
              </div>
              <div class="suara-rakyat">
                <p>
                  Suarakan harapan, aspirasi, dan gagasan terbaikmu untuk para
                  capres-cawapres!
                </p>
                <div class="text-center">
                  <VLazyImage
                    src="/storage/images/suara-penentu/sampaikan-suaramu.svg"
                    alt="Sampaikan Suara"
                  />
                </div>
                <button
                  type="button"
                  class="btn-explore-profile"
                  @click="chatbot()"
                >
                  Sampaikan suara
                </button>
              </div>
              <div
                class="terpopuler"
                v-show="
                  $store.state.suaraPenentu.terpopuler.items &&
                  $store.state.suaraPenentu.terpopuler.items.length > 0
                "
              >
                <TitleSection title="TERPOPULER" borderColor="#ff4800" />
                <div class="list-artikel-terpopuler">
                  <div
                    class="article"
                    v-for="(populer, index) in this.$store.state.suaraPenentu
                      .terpopuler.items"
                    :key="`terpopuler-${index}`"
                  >
                    <Link v-if="populer.type === 'video'" :to="populer.slug"
                      ><i class="material-icons">play_circle</i>
                      {{ populer.title }}</Link
                    >
                    <Link v-else :to="populer.slug">{{ populer.title }}</Link>
                  </div>
                </div>
                <div
                  class="button-show-more"
                  :class="{
                    'mt-5':
                      $store.state.suaraPenentu.terpopuler.loadingShowMore,
                  }"
                >
                  <span
                    v-if="
                      !$store.state.suaraPenentu.terpopuler.loadingShowMore &&
                      pagePopuler < $store.state.suaraPenentu.terpopuler.pages
                    "
                    @click="loadMorePopuler"
                    >LIHAT LEBIH BANYAK</span
                  >
                  <div
                    v-if="$store.state.suaraPenentu.terpopuler.loadingShowMore"
                    class="dot-spin m-auto"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="tertiary is-mobile">
            <div class="list-article-terkini-mobile-bottom">
              <template v-if="$store.state.suaraPenentu.terkini.loading">
                <ShimmerCardHomeLandscape
                  v-for="(item, index) in 5"
                  :key="index"
                />
              </template>
              <div
                v-show="!$store.state.suaraPenentu.terkini.loading"
                v-for="(
                  article, index
                ) in this.$store.state.suaraPenentu.terkini.items.slice(5)"
                :key="`list-article-terkini-${index}`"
                class="article-terkini"
              >
                <Link :to="article.slug">
                  <div class="position-relative">
                    <VLazyImage
                      :src="`https://images.narasi.tv/preset:sharp/resize:fill:312:174:1/gravity:ce/plain/${article.thumbnail}@webp`"
                      alt=""
                    />
                    <span class="time-video">{{
                      article.type === "read" ? "" : article.timeVideo
                    }}</span>
                  </div>
                  <h3>{{ article.title }}</h3>
                </Link>
              </div>
              <div
                class="button-show-more is-mobile"
                :class="{
                  'mt-5': $store.state.suaraPenentu.terkini.loadingShowMore,
                }"
              >
                <span
                  v-if="
                    pageArticles < $store.state.suaraPenentu.terkini.pages &&
                    !$store.state.suaraPenentu.terkini.loadingShowMore
                  "
                  @click="loadMoreTerkini"
                  >LIHAT LEBIH BANYAK</span
                >
                <div
                  v-if="$store.state.suaraPenentu.terkini.loadingShowMore"
                  class="dot-spin m-auto"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import VueWordCloud from "vuewordcloud";
import VLazyImage from "v-lazy-image/v2";
import { mapGetters } from "vuex";
import config from "../../config";
import ShimmerSliderKpu from "../../components/shimmer/suaraPenentu/SliderKpu.vue";
import ShimmerHighlight from "../../components/shimmer/suaraPenentu/Highlight.vue";
import TopAds from "../../components/ads/suaraPenentu/TopAds.vue";
import MiddleSquareAds from "../../components/ads/suaraPenentu/MiddleSquareAds.vue";
import MiddleCenterAds from "../../components/ads/suaraPenentu/MiddleCenterAds.vue";
import ModalInstagram from "../../components/modal/Instagram.vue";
import Paginate from "/node_modules/vuejs-paginate/src/components/Paginate";
import RekamJekak from "@/components/RekamJejak.vue";
import Icons from "@/components/Icons";
import Link from "@/components/Link";
import TitleSection from "@/components/TitleSection";
import ImageResponsive from "@/components/ImageResponsive";
import ShimmerCardHomeLandscape from "@/components/shimmer/CardHomeLandscape";
import QuickCount from "../../components/QuickCount.vue";

Vue.component("paginate", Paginate);

export default {
  components: {
    QuickCount,
    ShimmerCardHomeLandscape,
    ImageResponsive,
    TitleSection,
    Link,
    Icons,
    VueWordCloud,
    VLazyImage,
    ShimmerSliderKpu,
    ShimmerHighlight,
    TopAds,
    MiddleCenterAds,
    MiddleSquareAds,
    ModalInstagram,
    RekamJekak,
  },
  asyncData({ store, route, redirect }) {
    redirect(301, "/");
    store.commit("seo/SET_SEO", {
      title: "Suara Penentu",
      desc: "Satu suara dapat memicu gelombang perubahan. Dimulai dari suaramu, anak muda lainnya dapat terpicu untuk ikut serta dan memilih. Suara mu, Suara Penentu.",
      image: "https://narasi.tv/icon.png",
      url: `${config.BASE_URL}${route.fullPath}`,
      path: `${config.BASE_URL}${route.path}`,
    });
  },
  head() {
    return {
      ...this.headReturn,
    };
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
  },
  data() {
    return {
      pagePopuler: 1,
      pageArticles: 1,
      page: 1,
      screen: null,
      isOpenChatbot: false,
      showIG: false,
      showIndex: 0,
      swiperOptions: {
        highlightSlider: {
          autoplay: {
            delay: 5000,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            1023: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 8,
            },
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: (index, className) => {
              if (this.$store.state.suaraPenentu.highlights.items.length > 0) {
                if (window.innerWidth < 1000) {
                  return `<span class="swiper-pagination-bullet" tabindex="${index}" role="button" aria-label="Go to slide ${
                    index + 1
                  }"></span>`;
                } else {
                  return `
                  <div class="${className} swiper-pagination-bullet-thumb">
                    <div class="position-relative">
                      <img class="v-lazy-image" src="https://images.narasi.tv/preset:sharp/resize:fill:312:174:1/gravity:ce/plain/${
                        this.$store.state.suaraPenentu.highlights.items[index]
                          .thumbnail
                      }@webp">
                      <span class="time-video ${
                        this.$store.state.suaraPenentu.highlights.items[index]
                          .type !== "read"
                          ? "d-block"
                          : "d-none"
                      }" >${
                    this.$store.state.suaraPenentu.highlights.items[index]
                      .timeVideo
                  }</span>

                    </div>
                    <h3>
                      ${
                        this.$store.state.suaraPenentu.highlights.items[index]
                          .title
                      }
                    </h3>
                  </div>`;
                }
              } else {
                return "";
              }
            },
          },
        },
        KpuSlider: {
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          breakpoints: {
            1023: {
              slidesPerView: 4.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.5,
              spaceBetween: 8,
            },
          },
        },
        videoInfoPemilu: {
          freeMode: true,
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          navigation: {
            prevEl: ".prev-kenali-mentor",
            nextEl: ".next-kenali-mentor",
          },
          breakpoints: {
            1023: {
              slidesPerView: 4.5,
              spaceBetween: 16,
            },
            320: {
              slidesPerView: 1.5,
              spaceBetween: 8,
            },
          },
        },
      },
      reels: [
        {
          label: "Jejak Bacapres dalam Konflik Agraria",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail: "suara-penentu/jejak-bacapres-dalam-konflik-agraria.png",
          reel_id: "CxPe2AJL2jQ",
        },
        {
          label: "Kata Bacapres Soal Krisis Iklim",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail: "suara-penentu/kata-bacapres-soal-krisis-iklim.png",
          reel_id: "CwDCjomh57n",
        },
        {
          label: "Sikap Bacapres Soal UU ITE",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail: "suara-penentu/sikap-bacapres-soal-uu-ite.png",
          reel_id: "CvhgjYzNaJv",
        },
        {
          label: "Gagasan Bacapres Soal Pendidikan",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail: "suara-penentu/gagasan-bacapres-soal-pendidikan.png",
          reel_id: "CvUG6msBUyt",
        },
        {
          label: "Adu Gagasan Bacapres Soal Kota Masa Depan",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail:
            "suara-penentu/adu-gagasan-bacapres-soal-kota-masa-depan.png",
          reel_id: "CuqIO9rh2UD",
        },
        {
          label: "Skor Kebebasan Berpendapat di Indonesia Menurut Anies",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail:
            "suara-penentu/skor-kebebasan-berpendapat-di-indonesia-menurut-anies.png",
          reel_id: "CxXyF9PBABM",
        },
        {
          label: "Prabowo Siap Atasi Kemiskinan",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail: "suara-penentu/prabowo-siap-atasi-kemiskinan.png",
          reel_id: "CxNFEbVBItk",
        },
        {
          label: "Mimpi Ganjar, Gaji Guru Naik 30 Juta",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail: "suara-penentu/mimpi-ganjar-gaji-guru-naik-30-juta.png",
          reel_id: "CxMx8bBhSaE",
        },
        {
          label: "Prabowo akan Naikan Gaji ASN Biar gak Korupsi",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail:
            "suara-penentu/prabowo-akan-naikan-gaji-asn-biar-gak-korupsi.png",
          reel_id: "CxFSsrfPWBN",
        },
        {
          label: "Ganjar Sebut Perempuan Lebih Berani Dibanding Lelaki",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail:
            "suara-penentu/ganjar-sebut-perempuan-lebih-berani-dibanding-lelaki.png",
          reel_id: "CwaUn5YBxMM",
        },
        {
          label: "Anies Bicara Dampak Korean Wave",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail: "suara-penentu/anies-bicara-dampak-korean-wave.png",
          reel_id: "CwW8XSThOw0",
        },
        {
          label: "Opini Bacapres Soal Polusi Udara",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail: "suara-penentu/opini-bacapres-soal-polusi-udara.png",
          reel_id: "CvwgJshBOmN",
        },
        {
          label: "Prabowo: Sumber Daya Alam Harus Diolah di Indonesia",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail:
            "suara-penentu/prabowo-sumber-daya-alam-harus-diolah-di-indonesia.png",
          reel_id: "CuwmCovhGlq",
        },
        {
          label: "Ganjar: Ciptakan Ruang Enterpreneurship",
          account: "narasinewsroom",
          profile_picture: "suara-penentu/narasi-newsroom.png",
          thumbnail: "suara-penentu/ganjar-ciptakan-ruang-enterpreneurship.png",
          reel_id: "CuvXOf8haNi",
        },
      ],
      reel: {},
    };
  },
  methods: {
    titleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    loadMoreTerkini() {
      this.pageArticles = this.pageArticles + 1;
      this.$store.dispatch("suaraPenentu/getArticlesByTerkini", {
        tagsSlug: "suara-penentu",
        page: this.pageArticles,
        limit: 10,
      });
    },
    loadMorePopuler() {
      this.pagePopuler = this.pagePopuler + 1;
      this.$store.dispatch("suaraPenentu/getArticlesByTerpopuler", {
        tagsSlug: "suara-penentu",
        page: this.pagePopuler,
        limit: 6,
      });
    },
    chatbot(e) {
      this.isOpenChatbot = !this.isOpenChatbot;
    },
    showModalIG(i) {
      this.showIG = true;
      this.showIndex = i;
      this.reel = this.reels[i];
    },
    clickCallback(param) {
      this.page = param;
      this.$store.dispatch("suaraPenentu/getCaleg", param);
    },
    async initData() {
      await this.$store.dispatch("suaraPenentu/getArticlesByTags", {
        tagsSlug: "suara-penentu,suara-penentu-pinned",
        page: 1,
        limit: 5,
      });
      await this.$store.dispatch("suaraPenentu/getArticlesByKPU", {
        tagsSlug: "komisi-pemilihan-umum,kpu",
        page: 1,
        limit: 10,
      });
      await this.$store.dispatch("suaraPenentu/getArticlesByTerkini", {
        tagsSlug: "suara-penentu,suara-penentu-populer",
        page: 1,
        limit: 10,
        firstLoad: true,
      });
      await this.$store.dispatch("suaraPenentu/getArticlesByTerpopuler", {
        tagsSlug: "suara-penentu",
        page: 1,
        limit: 6,
        firstLoad: true,
      });
    },
  },
  mounted() {
    this.screen = window.innerWidth;
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.ads-main {
  padding: 16px 0;
}
.quick-count-wrapper {
  padding: 16px 0;
}
.suara-penentu {
  font-family: "Varela Round", sans-serif;

  .chatbot-wrapper {
    position: fixed;
    bottom: 43%;
    right: 36%;
    transform: translate(50%, 50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 997;
    pointer-events: none;
    &.active {
      z-index: 1001;
      pointer-events: visible;
    }
    @media only screen and (min-width: 1200px) {
      bottom: 43%;
      right: 24%;
    }
    @media only screen and (max-width: 1024px) {
      position: relative;
      bottom: unset;
      right: unset;
      transform: unset;
    }
    .chatbox-support {
      opacity: 0;
      pointer-events: none;
      transition: all 0.5s ease-in-out;
      margin-bottom: 16px;
      @media only screen and (max-width: 1024px) {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 999999;
        height: 100vh;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: #564480;
      }
      &.chatbox-active {
        opacity: 1;
        pointer-events: visible;
      }
      .close-wrapper {
        display: none;
        @media only screen and (max-width: 1024px) {
          height: 5vh;
          background: #564480;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        svg {
          @media only screen and (max-width: 1024px) {
            width: 5vw;
            height: 5vw;
            margin-right: 4vw;
          }
        }
      }

      iframe {
        border-radius: 12px;
        width: 25vw;
        height: 32vw;
        z-index: 1;
        position: relative;
        @media only screen and (max-width: 1500px) {
          width: 30vw;
          height: 35vw;
        }
        @media only screen and (max-width: 1024px) {
          width: 100vw;
          height: 85vh;
          border-radius: 0;
        }
      }
    }
    .notif {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      pointer-events: visible;
      @media only screen and (max-width: 1024px) {
        position: fixed;
        bottom: 10%;
        right: 15%;
        transform: translate(50%, 50%);
        z-index: 1001;
      }
      span {
        position: absolute;
        right: 0;
        font-size: 12px;
        color: #fff;
        width: 16px;
        height: 16px;
        background-color: #ff4800;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .countdown {
    padding: 51px 0;
    background-color: #fff;
    height: 300px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 24px 0;
      height: 350px;
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: 1024px) {
        flex-wrap: wrap;
      }
      .logo {
        width: 22%;
        @media only screen and (max-width: 1024px) {
          width: 113.999px;
          height: 43.5px;
        }
        img {
          width: 100%;
        }
      }
      .desc {
        width: 50%;
        @media only screen and (max-width: 1024px) {
          order: 3;
          margin-top: 24px;
          width: 100%;
        }
        p {
          font-size: 18px;
          color: #0c0b0d;
          @media only screen and (max-width: 1024px) {
            font-size: 14px;
          }
        }
      }
      .countdown-item {
        width: 22%;
        padding: 16px;
        background-color: #564480;
        border-radius: 12px;
        text-align: center;
        @media only screen and (max-width: 1024px) {
          width: 200px;
          border-radius: 8px;
          text-align: left;
          padding: 8px;
        }
        .countdown-wrapper {
          @media only screen and (max-width: 1024px) {
            display: flex;
            align-items: center;
          }
          .countdown-info {
            padding-top: 8px;
            font-size: 14px;
            color: #fff;
            @media only screen and (max-width: 1024px) {
              padding-top: 0;
              font-size: 10px;
              line-height: 12px;
              flex-wrap: wrap-reverse;
              margin-left: 4px;
            }
          }
          .countdown-day {
            width: 35px;
            height: 22.5px;
            display: inline-table;
            background: #fff;
            margin: 0 2px;
            border-radius: 7.5px;
            @media only screen and (max-width: 1024px) {
              text-align: center;
              height: 41.25px;
            }

            .countdown-day-item {
              display: inline;
              font-size: 27px;
              font-family: "Roboto", sans-serif;
              font-weight: 700;
              @media only screen and (max-width: 1024px) {
                padding: 10px;
              }
            }
          }
        }
      }
    }
  }
  .highlight {
    margin-top: -90px;
    margin-bottom: 60px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 0;
    }
    .container {
      @media only screen and (max-width: 1024px) {
        padding-right: 0;
        padding-left: 0;
      }
      .wrapper {
        background-color: #564480;
        border-radius: 20px;
        position: relative;
        padding: 32px 32px 48px 32px;
        height: 541px;
        @media only screen and (max-width: 1024px) {
          border-radius: 0;
          padding-right: var(--bs-gutter-x, 0.75rem);
          padding-left: var(--bs-gutter-x, 0.75rem);
          height: 285px;
          position: relative;
        }

        .slider-highlight-container {
          @media only screen and (max-width: 1024px) {
            top: -90px;
            border-radius: 10px;
          }
          .slider-highlight {
            .slider-highlight-item {
              display: flex;
              gap: 24px;
              @media only screen and (max-width: 1024px) {
                display: block;
              }

              .hero-img {
                width: 461px;
                height: 258px;
                flex-shrink: 0;
                object-fit: cover;
                @media only screen and (max-width: 1024px) {
                  border-radius: 10px;
                  width: 100%;
                  height: 192px;
                }
              }
              .time-video {
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.7);
                padding: 6px 8px;
                color: #fff;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                font-weight: 500;
                border-top-left-radius: 8px;
              }

              .description {
                padding-top: 24px;
                .title {
                  color: #fff;
                  font-size: 28px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  @media only screen and (max-width: 1024px) {
                    margin-top: 24px;
                    font-size: 20px;
                  }
                }

                .short {
                  font-size: 16px;
                  color: #fff;
                  opacity: 0.5;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  @media only screen and (max-width: 1024px) {
                    display: none;
                  }
                }
              }
            }
          }
          .swiper-button-prev {
            display: none;
            @media only screen and (max-width: 1024px) {
              display: block;
              top: 114px;
              &::after {
                color: #ffffff;
                font-size: 20px;
              }
              &.swiper-button-disabled {
                display: none;
              }
            }
          }
          .swiper-button-next {
            display: none;
            @media only screen and (max-width: 1024px) {
              display: block;
              top: 114px;
              &::after {
                color: #ffffff;
                font-size: 20px;
              }
              &.swiper-button-disabled {
                display: none;
              }
            }
          }
          ::v-deep .swiper-pagination {
            text-align: left !important;
            position: relative !important;
            margin-top: 32px;
            display: flex;
            justify-content: space-between;
            gap: 0;
            @media only screen and (max-width: 1024px) {
              justify-content: center;
              gap: 4px;
              position: absolute !important;
              bottom: 97px !important;
              left: 50%;
              transform: translateX(-50%);
            }

            .swiper-pagination-bullet {
              height: unset;
              background: #564480;
              margin: unset !important;
              opacity: 1;
              position: relative;
              padding: 0 10px;
              border-right: 1px solid rgba(251, 251, 251, 0.3);
              border-radius: 0;
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
                border: none;
              }
              @media only screen and (max-width: 1024px) {
                background: #fff;
                opacity: 0.5;
                width: 8px;
                height: 8px;
                padding: 0;
                border-radius: 100%;
              }
            }

            .swiper-pagination-bullet-active {
              background: #564480;
              @media only screen and (max-width: 1024px) {
                background: #fff;
                opacity: 1;
              }

              h3 {
                color: #ffb400 !important;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
            }

            .swiper-pagination-bullet-thumb {
              width: calc(100% / 5);

              .position-relative {
                img {
                  width: 100%;
                  height: 120px;
                  object-fit: cover;
                }

                .time-video {
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  background-color: rgba(0, 0, 0, 0.7);
                  padding: 6px 8px;
                  color: #fff;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  font-weight: 500;
                  border-top-left-radius: 8px;
                }
              }

              h3 {
                margin-top: 8px;
                font-size: 13px;
                color: #fff;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
    }
  }
  .video-info-pemilu {
    padding: 48px 0;
    background: #e2e2e2;
    .container {
      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .title {
          display: flex;
          gap: 16px;
        }
        a {
          color: #564480;
          font-size: 16px;

          &:hover {
            color: #564480 !important;
            font-weight: 600;
          }
        }
      }
      .list-video-info-pemilu {
        position: relative;
        overflow: hidden;
        @media only screen and (max-width: 1024px) {
          overflow: visible;
        }
        .shadow-arrow {
          pointer-events: none;
          position: absolute;
          top: 0;
          width: 100px;
          height: 100%;
          padding: 0;
          background: rgb(0, 0, 0);
          z-index: 2;
          opacity: 1;
          transition: ease-in-out 0.5s;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          svg {
            pointer-events: visible;
          }
          &.shadow-left {
            left: 0;
            background: linear-gradient(
              to left,
              hsla(0, 0%, 100%, 0),
              rgba(226, 226, 226, 1)
            );
          }

          &.shadow-right {
            right: 0;
            background: linear-gradient(
              to right,
              hsla(0, 0%, 100%, 0),
              rgba(226, 226, 226, 1)
            );
          }

          &.swiper-button-disabled {
            opacity: 0;
            z-index: 0;
          }
        }

        .arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          width: auto;
          @media only screen and (max-width: 1024px) {
            display: none;
          }

          &.prev {
            left: 0;
          }

          &.next {
            right: 0;
          }

          &.hide {
            display: none;
          }
        }

        .swiper-container {
          @media only screen and (max-width: 1024px) {
            padding: 20px !important;
            margin: -20px !important;
          }
          .swiper-wrapper {
            height: fit-content !important;
          }
        }

        .video-info-pemilu-card {
          flex-direction: column;
          align-items: center;
          width: 250px;
          height: fit-content;
          border-radius: 10px;
          overflow: hidden;
          background: white;
          cursor: pointer;
          @media only screen and (max-width: 1024px) {
            border-radius: 6px;
          }
          .video-info-pemilu-card-content {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 16px;
            margin: auto;
            display: flex;
            gap: 8px;
            align-items: center;
            .image {
              width: 1.8vw;
              height: 1.8vw;
              border-radius: 50rem;
              overflow: hidden;
              @media only screen and (max-width: 1024px) {
                width: 8vw;
                height: 8vw;
              }
              ::v-deep img {
                object-fit: cover;
              }
            }
            .account {
              font-size: 14px;
              color: #ffffff;
              margin: 0;
              @media only screen and (max-width: 1024px) {
                font-size: 16px;
              }
            }
            svg {
              width: 0.8vw;
              height: auto;
              @media only screen and (max-width: 1024px) {
                width: 4vw;
              }
            }
          }
          .icon-reels {
            position: absolute;
            top: 4%;
            right: 4%;
            width: 1.5vw;
            height: auto;
            @media only screen and (max-width: 1024px) {
              width: 7vw;
            }
          }
        }
      }
    }
  }
  .rekam-jejak {
    padding: 32px;
    background-color: #fff;
    @media only screen and (max-width: 1024px) {
      padding: 24px 0;
    }
  }
  .news-kpu {
    background-color: #564480;
    .container {
      padding: 32px 0;
      @media only screen and (max-width: 1024px) {
        padding: 32px 0.75rem;
      }
      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .title {
          display: flex;
          gap: 16px;
        }
        a {
          color: #ffb400;
          font-size: 16px;

          &:hover {
            color: #ffb400 !important;
            font-weight: 600;
          }
        }
      }
      .slider-kpu-container {
        position: relative;
        @media only screen and (max-width: 1024px) {
          margin: -20px !important;
          padding: 20px !important;
        }
        .slider-kpu {
          .slider-kpu-item {
            .thumbnail {
              position: relative;
              margin-bottom: 16px;
              img {
                width: 100%;
                height: 140px;
                border-radius: 4px;
                object-fit: cover;
              }
              .time-video {
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.7);
                padding: 6px 8px;
                color: #fff;
                font-size: 11px;
                font-family: "Roboto", sans-serif;
                font-weight: 500;
                border-top-left-radius: 8px;
              }
            }
            .title {
              font-size: 14px;
              margin: 0;
              color: #f1f1f1;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
        }
        .swiper-button-prev {
          color: #ffffff;
          top: 0;
          left: 0;
          height: 100%;
          width: 50px;
          margin: 0 !important;
          background: linear-gradient(
            272deg,
            rgba(71, 59, 105, 0) 2.21%,
            #473b69 98.25%
          );
          pointer-events: none;
          &::after {
            pointer-events: visible;
            color: #ffffff;
            font-size: 20px;
          }
          &.swiper-button-disabled {
            display: none;
          }
        }
        .swiper-button-next {
          color: #ffffff;
          top: 0;
          right: 0;
          left: auto;
          height: 100%;
          width: 50px;
          margin: 0 !important;
          background: linear-gradient(
            272deg,
            #473b69 2.21%,
            rgba(71, 59, 105, 0) 98.25%
          );
          pointer-events: none;
          &::after {
            pointer-events: visible;
            color: #ffffff;
            font-size: 20px;
          }
          &.swiper-button-disabled {
            display: none;
          }
        }
      }
    }
  }
  .terkini-sidebar {
    background-color: #fff;
    padding: 32px 0;
    .container {
      @media only screen and (max-width: 1024px) {
        padding: 0;
      }
      .wrapper {
        display: flex;
        gap: 32px;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
          .header-mobile {
            margin-top: 40px;
          }
        }
        .primary {
          width: 70%;
          @media only screen and (max-width: 1024px) {
            width: 100%;
            padding: 0 0.75rem;
          }
          .title {
            margin-bottom: 24px;
          }
          .list-article-terkini {
            .article-terkini {
              border-bottom: 1px solid #d9d9d9;
              padding: 24px 0;
              @media only screen and (max-width: 1024px) {
                padding: 16px 0;
              }
              &:first-child {
                padding-top: 0;
              }
              &:nth-last-child(2) {
                border-bottom: none !important;
              }

              a {
                display: flex;
                gap: 16px;
                align-items: center;

                img {
                  width: 220px;
                  min-width: 220px;
                  height: 124.103px;
                  min-height: 124.103px;
                  object-fit: cover;
                  @media only screen and (max-width: 1024px) {
                    min-width: 100px;
                    min-height: 100px;
                    width: 100px;
                    height: 100px;
                  }
                }

                .time-video {
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  background-color: rgba(0, 0, 0, 0.7);
                  padding: 6px 8px;
                  color: #fff;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  font-weight: 500;
                  border-top-left-radius: 8px;
                }

                h3 {
                  color: #0c0b0d;
                  font-size: 16px;
                  @media only screen and (max-width: 1024px) {
                    font-size: 14px;
                  }
                }
              }
            }
            .button-show-more {
              text-align: center;
              margin-bottom: 32px;
              span {
                cursor: pointer;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #4a25aa;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 700;
                color: #4a25aa;
                padding: 0 20px;
                @media only screen and (max-width: 1024px) {
                  height: 40px;
                  &:hover {
                    color: #4a25aa !important;
                  }
                }
                @media only screen and (min-width: 1024px) {
                  &:hover {
                    color: #8166c8 !important;
                    border-color: #8166c8 !important;
                  }
                }
              }
            }
          }
        }
        .secondary {
          width: 30%;
          @media only screen and (max-width: 1024px) {
            width: 100%;
          }
          .sidebar {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            .title {
              @media only screen and (max-width: 1024px) {
                margin: 0 0.75rem;
              }
            }
            .description {
              margin-bottom: 0;
              font-size: 14px;
              color: #7d7d7d;
              line-height: 130%;
              @media only screen and (max-width: 1024px) {
                padding: 0 0.75rem;
                font-size: 13px;
              }
            }

            iframe {
              position: relative;
              z-index: 998;
              width: 100%;
              height: 500px;
            }
            .tagcloud {
              width: 100%;
              height: 318px;
              background-color: #564480;
              border-radius: 12px;
              padding: 13px;
              @media only screen and (max-width: 1024px) {
                margin: 0 0.75rem;
              }
            }
            .suara-rakyat {
              width: 100%;
              background-color: #ffb400;
              border-radius: 12px;
              padding: 32px;
              @media only screen and (max-width: 1024px) {
                padding: 16px;
                margin: 0 0.75rem;
              }

              p {
                font-size: 24px;
                color: #564480;
              }

              img {
                margin: 0 auto;
                width: 100%;
              }

              .btn-explore-profile {
                padding: 12px 24px;
                width: 100%;
                border-radius: 50px;
                background: #564480;
                color: #fff;
                border: none;

                &:hover {
                  background: hwb(258 18% 49%);
                  color: #fff !important;
                }
              }
            }
            .terpopuler {
              width: 100%;
              background-color: rgba(221, 221, 221, 0.87);
              border-radius: 12px;
              padding: 32px;
              @media only screen and (max-width: 1024px) {
                padding: 16px;
                border-radius: 0;
              }
              .header {
                display: flex;
                justify-content: flex-start;
                .title {
                  display: flex;
                  gap: 16px;
                }
              }
              .list-artikel-terpopuler {
                display: flex;
                flex-direction: column;
                .article {
                  border-bottom: 1px solid #b3afaf;
                  padding: 16px 0;

                  &:last-child {
                    border-bottom: unset;
                  }

                  a {
                    color: #0c0b0d;
                    font-size: 14px;

                    .material-icons {
                      font-size: 14px;
                      color: #564480;
                    }

                    &:hover {
                      color: #564480 !important;
                    }
                  }
                }
              }
              .button-show-more {
                text-align: center;
                margin-top: 24px;
                span {
                  cursor: pointer;
                  height: 50px;
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #4a25aa;
                  border-radius: 4px;
                  font-size: 14px;
                  font-weight: 700;
                  color: #4a25aa;
                  padding: 0 20px;
                  @media only screen and (max-width: 1024px) {
                    height: 40px;
                    &:hover {
                      color: #4a25aa !important;
                    }
                  }
                  @media only screen and (min-width: 1024px) {
                    &:hover {
                      color: #8166c8 !important;
                      border-color: #8166c8 !important;
                    }
                  }
                }
              }
            }
          }
        }
        .tertiary {
          @media only screen and (max-width: 1024px) {
            padding: 0 0.75rem;
          }
          .list-article-terkini-mobile-bottom {
            .article-terkini {
              border-bottom: 1px solid #d9d9d9;
              padding: 24px 0;
              @media only screen and (max-width: 1024px) {
                padding: 16px 0;
              }
              &:nth-child(1) {
                padding-top: 16px;
              }

              &:last-child {
                border-bottom: unset !important;
              }

              a {
                display: flex;
                gap: 16px;
                align-items: center;

                img {
                  width: 220px;
                  min-width: 220px;
                  height: 124.103px;
                  min-height: 124.103px;
                  object-fit: cover;
                  @media only screen and (max-width: 1024px) {
                    min-width: 100px;
                    min-height: 100px;
                    width: 100px;
                    height: 100px;
                  }
                }

                .time-video {
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  background-color: rgba(0, 0, 0, 0.7);
                  padding: 6px 8px;
                  color: #fff;
                  font-size: 11px;
                  font-family: "Roboto", sans-serif;
                  font-weight: 500;
                  border-top-left-radius: 8px;
                }

                h3 {
                  color: #0c0b0d;
                  font-size: 16px;
                  @media only screen and (max-width: 1024px) {
                    font-size: 14px;
                  }
                }
              }
            }
            .button-show-more {
              text-align: center;
              margin-bottom: 32px;
              margin-top: 32px;
              span {
                cursor: pointer;
                height: 50px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #4a25aa;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 700;
                color: #4a25aa;
                padding: 0 20px;
                @media only screen and (max-width: 1024px) {
                  height: 40px;
                  &:hover {
                    color: #4a25aa !important;
                  }
                }
                @media only screen and (min-width: 1024px) {
                  &:hover {
                    color: #8166c8 !important;
                    border-color: #8166c8 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
