var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget-wrapper"},[_c('QuickCountWidget',{attrs:{"intervalProvider":{
      delay: 10000,
    },"intervalZone":{
      delay: 3000,
    },"intervalParpol":{
      delay: 3000,
    },"linkSelengkapnya":null,"showKpu":true,"showTable":true,"showProvider":true,"showParpol":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }