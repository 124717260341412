var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-wrapper"},[_c('div',{staticClass:"quick-count-wrapper"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"ads-main"},[_c('Billboard')],1),_vm._v(" "),_c('QuickCount',{attrs:{"linkSelengkapnya":null,"showKpu":true,"showTable":true,"showProvider":true,"showParpol":true}}),_vm._v(" "),_c('div',{staticClass:"share-container"},[_c('div',{staticClass:"share-wrapper"},[_c('a',{staticClass:"sosmed-wrapper",attrs:{"href":_vm.setLink('whatsapp'),"target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"color":"#57C038","name":"logo-whatsapp"}})],1),_vm._v(" "),_c('a',{staticClass:"sosmed-wrapper",attrs:{"href":_vm.setLink('twitter'),"target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"color":"#000000","name":"logo-x"}})],1),_vm._v(" "),_c('a',{staticClass:"sosmed-wrapper",attrs:{"href":_vm.setLink('facebook'),"target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"color":"#415893","name":"logo-facebook"}})],1),_vm._v(" "),_c('div',{staticClass:"sosmed-wrapper",on:{"click":function($event){return _vm.copyLink()}}},[_c('Icons',{attrs:{"color":"#D0D0D0","name":"logo-copy-link"}})],1)])])],1)]),_vm._v(" "),_c('section',{staticClass:"terkini-sidebar"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"primary"},[_c('TitleSection',{attrs:{"title":"ARTIKEL TERKAIT","borderColor":"#FF4800"}}),_vm._v(" "),_c('div',{staticClass:"list-article-terkini"},[(_vm.$store.state.suaraPenentu.terkini.loading)?_vm._l((5),function(item,index){return _c('ShimmerCardHomeLandscape',{key:index})}):_vm._e(),_vm._v(" "),_vm._l((_vm.screen < 1000
                ? _vm.$store.state.suaraPenentu.terkini.items.slice(0, 5)
                : _vm.$store.state.suaraPenentu.terkini.items),function(article,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$store.state.suaraPenentu.terkini.loading),expression:"!$store.state.suaraPenentu.terkini.loading"}],key:`list-article-terkini-${index}`,staticClass:"article-terkini"},[_c('Link',{attrs:{"to":article.slug}},[_c('div',{staticClass:"position-relative"},[_c('VLazyImage',{attrs:{"src":`https://images.narasi.tv/preset:sharp/resize:fill:312:174:1/gravity:ce/plain/${article.thumbnail}@webp`,"alt":""}}),_vm._v(" "),_c('span',{staticClass:"time-video"},[_vm._v(_vm._s(article.type === "read" ? "" : article.timeVideo))])],1),_vm._v(" "),_c('h3',[_vm._v(_vm._s(article.title))])])],1)}),_vm._v(" "),_c('div',{staticClass:"button-show-more",class:{
                'mt-5': _vm.$store.state.suaraPenentu.terkini.loadingShowMore,
              }},[(
                  _vm.pageArticles < _vm.$store.state.suaraPenentu.terkini.pages &&
                  !_vm.$store.state.suaraPenentu.terkini.loadingShowMore
                )?_c('span',{on:{"click":_vm.loadMoreTerkini}},[_vm._v("LIHAT LEBIH BANYAK")]):_vm._e(),_vm._v(" "),(_vm.$store.state.suaraPenentu.terkini.loadingShowMore)?_c('div',{staticClass:"dot-spin m-auto"}):_vm._e()])],2)],1),_vm._v(" "),_c('div',{staticClass:"secondary"},[_c('MiddleSquareAds')],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }